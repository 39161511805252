import React from 'react';
import {Button} from 'antd';
import {navigate} from 'gatsby';
import * as YahooMallActions from '../../Actions/YahooMall';
import * as AppActions from '../../AppActions';

const pageSizeOptions = [10, 30, 50];
const sortOptions = [
  {name: '價錢高到低', value: '-price'},
  {name: '價錢低到高', value: 'price'},
];

async function fetchMany(
  {sort, paging, keyword, startDate, endDate, itemStatus},
  onSuccess,
  onFailure,
) {
  console.log('fetchMany', startDate, endDate);
  try {
    AppActions.setLoading(true);
    const {total, results} = await YahooMallActions.fetchCategoryList(
      {
        /*
        update_time_from: Math.floor(new Date(startDate).getTime() / 1000),
        update_time_to: Math.floor(new Date(endDate).getTime() / 1000),
        item_status: [itemStatus],
      */
      },
      paging,
    );
    onSuccess({total, results});
  } catch (ex) {
    console.warn('API error', ex);
    onFailure();
  } finally {
    AppActions.setLoading(false);
  }
}

const makeColumns = () => [
  {
    title: 'id',
    key: 'Id',
    dataIndex: 'Id',
    width: 100,
    fixed: 'left',
  },
  {
    title: 'Path',
    key: '_Path',
    dataIndex: '_Path',
    width: 500,
  },
  {
    title: '',
    key: 'detail',
    render: (_, record) => {
      return (
        <Button
          onClick={() =>
            navigate(`/admin/yahoo-mall/products?catId=${record.Id}`)
          }>
          DETAIL
        </Button>
      );
    },
  },
];

export {pageSizeOptions, sortOptions, fetchMany, makeColumns};
