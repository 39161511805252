import React from 'react';
import styled from 'styled-components';
import {Table, Pagination, Input, Select, Button} from 'antd';
import {useOutlet} from 'reconnect.js';
import {pageSizeOptions, sortOptions, fetchMany, makeColumns} from './Define';
import {PageTitle} from '../../Components/Widgets';

function getExtraTableProps(dimension) {
  if (!dimension.innerWidth) {
    return {};
  }

  if (dimension.rwd === 'mobile') {
    return {scroll: {x: dimension.innerWidth - 40}};
  } else {
    return {scroll: {x: dimension.innerWidth - 280}};
  }
}

const ItemStatus = ['NORMAL', 'BANNED', 'DELETED', 'UNLIST'];

function AdminProductListPage(props) {
  const [fetchResp, setFetchResp] = React.useState(null);
  const [keyword, setKeyword] = React.useState('');
  const [keywordTmp, setKeywordTmp] = React.useState('');
  const [sort, setSort] = React.useState(sortOptions[0].value);
  const [pageSize, setPageSize] = React.useState(pageSizeOptions[0]);
  const [paging, setPaging] = React.useState({offset: 0, limit: pageSize});
  const [lastRespTime, setLastRespTime] = React.useState(0);
  const [itemStatus, setItemStatus] = React.useState(ItemStatus[0]);
  const startDateRef = React.useRef();
  const endDateRef = React.useRef();
  const [dimension] = useOutlet('dimension');

  const fetchData = React.useCallback(async () => {
    await fetchMany(
      {
        sort,
        paging,
        keyword,
        startDate: startDateRef.current.value,
        endDate: endDateRef.current.value,
        itemStatus,
      },
      (result) => {
        setFetchResp(result);
        setLastRespTime(new Date().getTime());
      },
      () => {
        setFetchResp(null);
        setLastRespTime(new Date().getTime());
      },
    );
  }, [sort, keyword, paging, itemStatus]);

  React.useEffect(() => {
    fetchData();
  }, [fetchData]);

  return (
    <Wrapper>
      <PageTitle>Yahoo Mall商品分類</PageTitle>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '10px 0',
        }}>
        <div style={{width: 80}}>
          <div>每頁顯示</div>
          <Select
            value={pageSize}
            onChange={(nextPageSize) => {
              setPageSize(nextPageSize);
              setPaging({offset: 0, limit: nextPageSize});
            }}>
            {pageSizeOptions.map((opt) => (
              <Select.Option key={opt} value={opt}>
                {opt}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{width: 120}}>
          <div>狀態</div>
          <Select
            disabled
            value={itemStatus}
            onChange={(opt) => {
              setItemStatus(opt);
              setPaging({offset: 0, limit: pageSize});
            }}>
            {ItemStatus.map((opt) => (
              <Select.Option key={opt} value={opt}>
                {opt}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{width: 120}}>
          <div>排序</div>
          <Select
            disabled
            value={sort}
            onChange={(nextSort) => {
              setSort(nextSort);
              setPaging({offset: 0, limit: pageSize});
            }}>
            {sortOptions.map((opt) => (
              <Select.Option key={opt.value} value={opt.value}>
                {opt.name}
              </Select.Option>
            ))}
          </Select>
        </div>

        <div style={{width: 300, marginLeft: 10}}>
          <div>搜尋 {keyword ? `"${keyword}"` : ''}</div>
          <Input.Search
            disabled
            value={keywordTmp}
            onChange={(e) => setKeywordTmp(e.target.value)}
            onSearch={() => {
              setKeyword(keywordTmp);
              setPaging({offset: 0, limit: pageSize});
            }}
          />
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          margin: '10px 0',
        }}>
        <div style={{width: 200}}>
          <div>起始日期</div>
          <input
            type="date"
            ref={startDateRef}
            defaultValue={'2000-01-01'}
            disabled
          />
        </div>

        <div style={{marginLeft: 10, width: 200}}>
          <div>結束日期</div>
          <input
            type="date"
            ref={endDateRef}
            defaultValue={new Date().toISOString().split('T')[0]}
            disabled
          />
        </div>

        <Button size="large" onClick={() => fetchData()} disabled>
          搜尋日期範圍
        </Button>
      </div>

      {fetchResp && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row-reverse',
            marginBottom: 10,
          }}>
          <Pagination
            total={fetchResp.total}
            current={paging.offset / pageSize + 1}
            pageSize={pageSize}
            showQuickJumper
            showTotal={(total) => '共 ' + total + ' 筆'}
            onChange={(page, pageSize) => {
              setPaging({
                offset: (page - 1) * pageSize,
                limit: pageSize,
              });
            }}
            style={{marginTop: 20}}
          />
        </div>
      )}

      {fetchResp && (
        <Table
          key={lastRespTime}
          dataSource={fetchResp.results}
          rowKey="id"
          pagination={false}
          columns={[...makeColumns()]}
          {...getExtraTableProps(dimension)}
        />
      )}

      <div style={{display: 'flex', alignItems: 'center', padding: '20px 0'}}>
        <Button size="large" onClick={() => fetchData()} style={{margin: 10}}>
          重新整理
        </Button>
      </div>
    </Wrapper>
  );
}

const Wrapper = styled.div`
  padding: 20px;
`;

export default AdminProductListPage;
