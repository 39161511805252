import {getOutlet} from 'reconnect.js';
import Config from '../../data.json';
import {req} from '../Utils/ApiUtils';

const UserOutlet = getOutlet('user');

async function fetchProductList(query, paging) {
  const {catId} = query;
  const resp = await req(
    `${Config.apiHost}/yahoo-mall/product/list?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
      data: {
        StoreCategoryId: catId,
        ProductStatus: 'Online',
        // Keyword: 'string',
        // OrderBy: 'string',
        Position: 0,
        Count: 100,
      },
    },
  );

  const {
    Response: {
      SuccessList: {
        ProductList: {Product: products},
      },
    },
  } = resp;

  return {
    total: products.length,
    results: products.slice(paging.offset, paging.offset + paging.limit),
  };
}

async function fetchCategoryList(query, paging) {
  const resp = await req(
    `${Config.apiHost}/yahoo-mall/store/category/get?token=${
      UserOutlet.getValue().token
    }`,
    {
      method: 'POST',
    },
  );

  const {
    Response: {
      StoreCategoryList: {StoreCategory: categories},
    },
  } = resp;

  return {
    total: categories.length,
    results: categories.slice(paging.offset, paging.offset + paging.limit),
  };
}

export {fetchProductList, fetchCategoryList};
